.testimonial-card {
    background-color: white;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    margin: 20px auto;
}

.testimonial-title {
    color:black;
    font-size: 24px;
    margin-bottom: 16px;
    font-family: "Lato";
}

.testimonial-text {
    font-size: 16px;
    color: #333;
    font-family: "Lato";
}
