.hero1 {
  position: relative;
  background-image: url("../assets/aboutUs.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
  overflow: hidden;
}

.hero1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(40, 40, 40, 0.5);
  z-index: 1;
}

.container1 {
  display: flex;
  justify-content: space-between;
  text-align: left;
  position: relative;
  z-index: 2;
}

.heading1 {
  font-size: 5vw;

}

.leftCol1 {
  margin-left: 2em;
  margin-top: 2em;
}

.subHead1 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: rgb(4, 4, 4);
}

.testemonialContainer
{
  width: calc(100% - 4em);
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-left: 2em;
  margin-right: 2em;
}