
@import url('https://fonts.googleapis.com/css?family=Lato');
.editableTable {
    width: 50%;
    border-collapse: collapse;
    padding:300px;
    margin-left:20px;
    margin-top:100px;
    font-family: "Lato";
}

.editableTable th, .editableTable td {
    border: 1px solid #ddd;
    padding: 8px;
}

.editableTable th {
    background-color: #f2f2f2;
    text-align: left;
}

.editableTable td input {
    width: 100%;
    border: none;
    padding: 8px;
}

.editableTable td input:focus {
    outline: none;
}

.button{
    width:10%;
    margin-left:20px;
}