@import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');

body
{
    margin: 0; 
    padding: 0;
    font-family: 'Calibri', sans-serif;
    user-select: none;
    scroll-behavior: smooth;
    background-color: var(--bg);
}

h1
{
    color: rgb(225, 225, 225);
    font-family: 'Bebas Neue', sans-serif;
    font-size: 5rem;
}

h2
{
    color: rgb(225, 225, 225);
    font-family: 'Bebas Neue', sans-serif;
    font-size: 2.5rem;
}

