@import url('https://fonts.googleapis.com/css?family=Lato');

.fact-card {
    background-color: white;
    padding: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 25px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    height: 25vmin;
    margin: 20px auto;
    padding:60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.fact-title, .fact-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; /* ensures the text doesn't overflow the card */
    text-align: center; /* centers the text within its width */
    margin: 0;
    font-size: 2vw;
    padding:60px;

}

.fact-title {
    font-size: 2.5vw;
    /* margin-bottom: 16px; */
    font-family: "Lato";
    color: black;
    padding:80px;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.fact-text {
    opacity: 0;
    visibility: hidden;
    font-size: 1.15vw;
    padding:80px;
    color: #333;
    font-family: "Lato";
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.fact-card:hover .fact-text {
    opacity: 1;
    visibility: visible;
}

.fact-card:hover .fact-title {
    opacity: 0;
    visibility: hidden;
    padding:30px;
}
